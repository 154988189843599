import { usePaymentUpdateCompletePageAssets } from "@/features/Payments/Update/usePaymentUpdateCompletePageAssets";
import {
  type PaymentsUpdateForm,
  usePaymentsUpdateForm,
} from "@/features/Payments/Update/usePaymentsUpdateForm";
import { usePaymentCards } from "@/hooks/usePaymentCards";
import { path, enable3dsAuthentication } from "@/routes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const usePaymentsUpdateServiceFeature = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { cards, status } = usePaymentCards();
  const currentCard = cards.findLast((card) => {
    return (
      card.services.filter((service) => service.clientId === clientId).length >
      0
    );
  });

  const selectableCards = cards.filter((card) => {
    return card.id !== currentCard?.id && card.status === "valid";
  });

  const targetService = currentCard?.services.findLast(
    (service) => service.clientId === clientId,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [spsvToken, setSpsvToken] = useState<string | undefined>(undefined);

  const [holderName, setHolderName] = useState<string>();

  const { setValue: setPaymentUpdateCompleteValues } =
    usePaymentUpdateCompletePageAssets();

  const location = useLocation();

  const {
    handleUpdateApiRequest,
    handleRegisterApiRequest,
    handleSubmit,
    registers,
    errors: formErrors,
    watch,
    showNewCardForm,
    currentCardBrand,
    errorCardType,
    createSpsvToken,
    disableButton,
  } = usePaymentsUpdateForm(
    selectableCards.length > 0,
    status,
    async (token) => {
      setSpsvToken(token);
    },
  );

  useEffect(() => {
    const f = async () => {
      if (!spsvToken) {
        return;
      }
      const applyToOtherServices = watch("applyToOtherServices");
      if (enable3dsAuthentication) {
        handleRegisterApiRequest(
          spsvToken,
          holderName ?? "",
          currentCard?.id ?? "",
          targetService?.clientId
            ? `/account/payments/update/service/${targetService.clientId}`
            : "/account/payments",
          applyToOtherServices ? undefined : targetService?.serviceId,
        );
      } else {
        await handleUpdateApiRequest({
          card_token: spsvToken,
          service_id: applyToOtherServices
            ? undefined
            : targetService?.serviceId,
          old_card_id: currentCard?.id ?? "",
        });
        setIsSubmitting(false);
      }
    };
    f();
  }, [spsvToken]);

  useEffect(() => {
    if (
      status === "ok" &&
      (currentCard === undefined || targetService === undefined)
    ) {
      navigate(path.payments.root, { replace: true });
    }
  }, [currentCard, targetService, status]);

  if (
    currentCard === undefined ||
    targetService === undefined ||
    status !== "ok"
  ) {
    return { status: "loading" } as const;
  }

  const onSubmit = async (data: PaymentsUpdateForm) => {
    setIsSubmitting(true);
    const updatedService = data.applyToOtherServices
      ? currentCard.services.map((service) => {
          return {
            serviceId: service.serviceId,
            name: service.name,
            planName: service.planName,
          };
        })
      : [
          {
            serviceId: targetService.serviceId,
            name: targetService.name,
            planName: targetService.planName,
          },
        ];
    setPaymentUpdateCompleteValues({
      services: updatedService,
      backButtonLink: location.state?.destinationAfterPaymentsUpdate,
    });
    if (data.cardId === "0") {
      if (enable3dsAuthentication) {
        setHolderName(data.newCard.holderName);
      }
      const result = await createSpsvToken(data.newCard);
      if (result === "script_not_loaded") {
        setIsSubmitting(false);
      }
      return;
    }
    await handleUpdateApiRequest({
      new_card_id: data.cardId,
      old_card_id: currentCard.id,
      service_id: data.applyToOtherServices
        ? undefined
        : targetService?.serviceId,
    });
    setIsSubmitting(false);
  };

  return {
    status: "ok",
    currentCard,
    selectableCards,
    service: targetService,
    otherServices: currentCard.services
      .filter((service) => service.clientId !== clientId)
      .map((service) => ({
        name: service.name,
        planName: service.planName,
      })),
    registers,
    onSubmit: handleSubmit(onSubmit),
    onComposition: registers.onComposition,
    showNewCardForm,
    currentCardBrand,
    formErrors,
    errorCardType,
    disableButton: disableButton || isSubmitting,
  } as const;
};
