import {
  Box,
  ButtonBase as Button,
  Checkbox,
  ExternalLinkIcon,
  Link,
  Typography,
} from "nikkei-ui";

import { Trans, useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { useWithdrawFeature } from "@/features/Withdraw/hooks/useWithdrawFeature";
import { path } from "@/routes";
import type { RegisteredRelyingPartiesResponse } from "nid-common/api/account";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { AlertMessage } from "@/components/ui/Message/AlertMessage";
import clsx from "clsx";
import { PageSkeletonLoader } from "nid-common";

type RegisteredRelyingPartiesProps = {
  services: Array<RegisteredRelyingPartiesResponse>;
};

const RegisteredRelyingParties = ({
  services,
}: RegisteredRelyingPartiesProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner">
          <Box className="nid-list-subheader">
            <Typography
              as="h2"
              size="16"
              bold
              data-testid="article-withdraw-subheader"
            >
              <Box className="nid-section-title">
                {t("withdraw.index.services")}
              </Box>
            </Typography>
          </Box>
          <ul className="nid-list">
            {services.map((item, index) => {
              return (
                <li
                  key={item.client_id}
                  className={clsx(
                    "nid-list-item",
                    index === services.length - 1
                      ? "nid-account-item--noborder"
                      : "nid-account-item",
                  )}
                  data-testid="withdraw-required-service"
                >
                  {item.setting_link || item.has_setting_page ? (
                    <Link
                      href={
                        item.setting_link ||
                        `${import.meta.env.BASE_URL}${path.services.service(item.client_id)}`
                      }
                      className="nid-withdraw-item"
                      newTab
                    >
                      <Typography
                        as="span"
                        className="nid-account-item-text"
                        color="link"
                      >
                        {item.service_name}
                      </Typography>

                      <Box className="nid-list-item-right-icon">
                        <ExternalLinkIcon />
                      </Box>
                    </Link>
                  ) : (
                    <>
                      <Typography as="span" className="nid-account-item-text">
                        {item.service_name}
                      </Typography>
                    </>
                  )}
                  {item.caution && (
                    <Typography as="span" size="14">
                      {item.caution}
                    </Typography>
                  )}
                  {item.bp_service_detail?.map((item) => {
                    return (
                      <Typography as="span" size="14" key={item.service_name}>
                        {item.service_name}
                      </Typography>
                    );
                  })}
                </li>
              );
            })}
          </ul>
        </Box>
      </Box>
      <Box className="nid-item-center mt-10 mb-4">
        <BackButton
          to={path.root}
          buttonText={t("withdraw.index.button.back")}
        />
      </Box>
    </>
  );
};

export const WithdrawFeature = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    email,
    status,
    data,
    handleSubmit,
    isSubmitting,
    isChecked,
    handleCheckboxChange,
  } = useWithdrawFeature();

  if (status !== "ok") return <PageSkeletonLoader />;

  if (data?.length) {
    return (
      <>
        <ArticleTitle
          title={t("withdraw.index.title")}
          testId="article-withdraw"
        >
          <Typography as="p" className="nid-article-description">
            {t("withdraw.index.description")}
          </Typography>
        </ArticleTitle>
        <RegisteredRelyingParties services={data} />
      </>
    );
  }

  return (
    <>
      <ArticleTitle title={t("withdraw.index.title")} testId="article-withdraw">
        <Typography as="p" className="nid-article-description">
          {t("withdraw.confirm.description")}
        </Typography>
      </ArticleTitle>
      <Box as="section" className="nid-section-without-background">
        <Box className="nid-section-inner">
          <AlertMessage
            title={t("withdraw.confirm.caution_title")}
            testId="withdraw-caution-alert"
          >
            <Trans
              t={t}
              i18nKey="withdraw.confirm.caution_body"
              values={{ email }}
              shouldUnescape={true}
              components={[
                <Typography key="email" as="span" size="14" color="red90" />,
              ]}
            />
          </AlertMessage>
          <Box className="nid-item-center">
            <Checkbox
              label={t("withdraw.confirm.confirm_checkbox")}
              onChange={handleCheckboxChange}
              data-testid="confirm-check"
            />
          </Box>
          <Box className="nid-section-body">
            <Button
              onClick={handleSubmit}
              size="full"
              data-testid="submit"
              className="mt-4 mb-4"
              disabled={isSubmitting || !isChecked}
            >
              {t("withdraw.confirm.button.submit")}
            </Button>
          </Box>
          <Box className="nid-item-center mb-1">
            <Link to={path.root}>
              <Typography color="link">
                {t("withdraw.confirm.button.back")}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WithdrawFeature;
