import type React from "react";

import { Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { ServiceContractCancelAlertFeature } from "@/features/Services/Contract/Cancel/ServiceContractAlertFeature";
import { ServiceContractCancelConfirmFeature } from "@/features/Services/Contract/Cancel/ServiceContractCancelConfirmFeature";
import { useContractDetail } from "@/hooks/useContractDetail";
import { useServicePage } from "@/pages/Services/Service/hooks/useServicePage";
import { ContentSkeletonLoader } from "nid-common";

export const ServiceContractCancelConfirmPage: React.FC = () => {
  const { t } = useTranslation();
  const { clientId: serviceId } = useServicePage();
  const { status, contractDetail } = useContractDetail(serviceId);

  return (
    <>
      <ServiceContractCancelAlertFeature />
      <ArticleTitle
        title={t("services.service_contract_cancel_confirm.title")}
        testId="article-service-contract-cancel-confirm"
      >
        {status === "ok" ? (
          <Typography as="p" className="nid-article-description">
            {contractDetail.isPaidPlan
              ? t(
                  "services.service_contract_cancel_confirm.description.paid_plan",
                )
              : t(
                  "services.service_contract_cancel_confirm.description.free_plan",
                )}
          </Typography>
        ) : (
          <ContentSkeletonLoader />
        )}
      </ArticleTitle>
      <ServiceContractCancelConfirmFeature />
    </>
  );
};

export default ServiceContractCancelConfirmPage;
