import type React from "react";

import { Box } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { path } from "@/routes";

import { Breadcrumbs } from "../Breadcrumbs";

import { NavigationList } from "@/components/ui/NavigationList/NavigationList";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Footer, FooterNavigation } from "../Footer/Footer";
import styles from "./Layout.module.css";

export type LayoutProps = {
  title?: React.ReactNode;
  breadcrumbs: React.ReactNode;
  children: React.ReactNode;
  layoutClassName?: string;
  description?: string;
};

/**
 * Layout
 *
 * @example
 * ```ts
 *  <Layout>children</Layout>
 * ```
 * @param __namedParameters LayoutProps
 * @returns JSX.Element
 * @category ReactComponents
 */
export const Layout: React.FC<LayoutProps> = ({
  title,
  breadcrumbs,
  children,
  layoutClassName = "",
  description,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const navList = [
    {
      title: t("layout.navigation.title.top"),
      to: path.root,
    },
    {
      title: t("layout.navigation.title.personal"),
      to: path.personal.root,
    },
    {
      title: t("layout.navigation.title.payments"),
      to: path.payments.root,
    },
    {
      title: t("layout.navigation.title.security"),
      to: path.security.root,
    },
    {
      title: t("layout.navigation.title.services"),
      to: path.services.root,
    },
    {
      title: t("layout.navigation.title.newsletters"),
      to: path.newsletters.root,
    },
  ];

  return (
    <>
      {(title || description) && (
        <Helmet key={location.pathname}>
          {title && (
            <title>
              {title} | {t("site_name")}
            </title>
          )}
          {description && <meta name="description" content={description} />}
        </Helmet>
      )}
      <Box as="div" className={`nid-layout ${layoutClassName}`}>
        <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
        <Box as="div" className="nid-container">
          <Box className="nid-main">{children}</Box>
        </Box>
        <NavigationList
          list={navList.map((item) => {
            return {
              label: item.title,
              to: item.to,
            };
          })}
          className={`nid-visible-mobile ${styles.navigationList}`}
        />
        <Footer>
          <FooterNavigation />
        </Footer>
      </Box>
    </>
  );
};

Layout.displayName = "Layout";
export default Layout;
