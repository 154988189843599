import React from "react";

import { Box, ButtonBase as Button, RadioTile } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { useMfaStatus } from "nid-common/hooks/useMfaStatus";

export const SecurityMfaMethodFeature: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pageLink, setPageLink] = React.useState("");

  const { status, data } = useMfaStatus();

  if (status !== "ok") {
    return <ContentSkeletonLoader />;
  }

  const handleClickHref = () => {
    let destination =
      data.mfa_status === "authenticator_app_code"
        ? path.security.mfa.authenticator.root
        : path.security.mfa.email.root;

    if (pageLink !== "") {
      destination = pageLink;
    }

    navigate(destination);
  };

  return (
    <Box className="nid-section-inner-gap24">
      <Box className="nid-column-item-start nid-item-gap8">
        <RadioTile
          value="1"
          name="mfa"
          id="mfa-app"
          defaultChecked={data.mfa_status === "authenticator_app_code"}
          label={t("security.mfa.top.select.authenticator.label")}
          subText={t("security.mfa.top.select.authenticator.subtext")}
          onChange={() => setPageLink(path.security.mfa.authenticator.root)}
        />

        <RadioTile
          value="2"
          name="mfa"
          id="mfa-email"
          defaultChecked={data.mfa_status === "email_code"}
          label={t("security.mfa.top.select.email.label")}
          subText={t("security.mfa.top.select.email.subtext")}
          onChange={() => setPageLink(path.security.mfa.email.root)}
        />
      </Box>

      <Box className="nid-item-center nid-item-gap16 my-4">
        <Button
          size="full"
          type="button"
          data-testid="submit"
          onClick={handleClickHref}
        >
          {t("security.edit.button.submit")}
        </Button>

        <BackButton
          to={path.security.mfa.root}
          buttonText={t("security.mfa.method.button.back")}
        />
      </Box>
    </Box>
  );
};
