import { Box, Checkbox, Textarea, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import RequiredTag from "@/components/ui/Tag/RequiredTag";
import type React from "react";
import type { UseFormRegisterReturn } from "react-hook-form";
import {
  type Choice,
  useContractAdjustTextareaHeight,
} from "./hooks/useContractCancel";

import styles from "./ContractCancel.module.css";

type Props = {
  choices: Choice[];
  text: {
    visible: boolean;
    enabled: boolean;
  };
  registers: {
    choices: (idx: number) => UseFormRegisterReturn<string>;
    textEnabled: UseFormRegisterReturn<string>;
    text: UseFormRegisterReturn<string>;
    textValue: string;
  };
};

export const maxLength = 250;

export const ContractCancelSurvey: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const watchedText = props.registers.textValue;
  const textLength = watchedText.length || 0;
  useContractAdjustTextareaHeight(watchedText);

  return (
    <Box as="section" className="nid-section nid-section-overflow mb-0">
      <Box className="nid-section-inner-gap8">
        <Typography
          as="h3"
          bold
          lineHeight="135"
          className="nid-typography--vertical-center"
          data-testid="contract-survey-title"
        >
          {t("services.service_contract_cancel.survey.title")}
          <RequiredTag on={false} />
        </Typography>
        <Typography as="p" lineHeight="165">
          {t("services.service_contract_cancel.survey.reason")}
        </Typography>
        <Box className="nid-section-body">
          {props.choices.map((choice) =>
            choice.label ? (
              <Checkbox
                label={choice.label}
                key={choice.index}
                data-testid={`contract-survey-choice-${choice.index}`}
                {...props.registers.choices(choice.index)}
              />
            ) : null,
          )}
          {props.text.visible ? (
            <>
              <Checkbox
                label={t("services.service_contract_cancel.survey.text_title")}
                value={"question_text"}
                data-testid="contract-survey-text-checkbox"
                {...props.registers.textEnabled}
              />
              <Box className={styles.textAreaBlock}>
                <Textarea
                  placeholder={t(
                    "services.service_contract_cancel.survey.placeholder",
                  )}
                  containerclassname="mb-2"
                  className="w-full nid-textarea-disabled-bgcolor"
                  maxLength={maxLength}
                  data-testid="contract-survey-text"
                  disabled={!props.text.enabled}
                  style={{
                    height: "auto",
                  }}
                  {...props.registers.text}
                />
                <Box className={styles.textAreaCounter}>
                  <Typography as="span" size="14" color="gray90">
                    ({textLength}/{maxLength})
                  </Typography>
                </Box>
              </Box>
              <Box className="mb-6">
                <Typography as="span" size="14" color="gray90">
                  {t("services.service_contract_cancel.survey.helper")}
                </Typography>
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
