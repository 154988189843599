import { PasskeyBenefits } from "@/components/ui/Passkeys/PasskeyBenefits";
import { path, enableSecurityPasskey } from "@/routes";
import type { ParseKeys } from "i18next";
import { ContentSkeletonLoader } from "nid-common";
import { AlertMessage, Box, Button, Modal, Typography } from "nikkei-ui";
import { AccountKeyIcon } from "nikkei-ui/components/Icons/AccountKeyIcon/AccountKeyIcon";
import type React from "react";
import { useTranslation } from "react-i18next";
import { PasskeyRevokeModalContent } from "./PasskeyRevokeModalContent";
import { useSecurityPasskeyFeature } from "./hooks/useSecurityPasskeyFeature";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { PasskeyItem } from "@/components/ui/Passkeys/PasskeyItem";
import { PasskeyInformationModalContent } from "./PasskeyInformationModalContent";
import styles from "./SecurityPasskeyFeature.module.css";
import { aaguidInfoFn, convertToAtDateTime } from "./formatPasskeyItem";

type PasskeyActionButtonsProps = {
  onRegisterPassKey: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
  errorId?: ParseKeys;
};

const PasskeyActionButtons: React.FC<PasskeyActionButtonsProps> = ({
  onRegisterPassKey,
  disabled,
  errorId,
}) => {
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onRegisterPassKey(event);
  };

  return (
    <Box className="nid-column nid-item-gap16 w-full">
      {errorId && (
        <Box className={"nid-button-full"}>
          <AlertMessage
            variant="danger"
            testId="passkey-error"
            className={styles.alert}
          >
            <Typography as="p" size="14" color="red90">
              {t(errorId)}
            </Typography>
          </AlertMessage>
        </Box>
      )}
      <Button
        size="full"
        data-testid="submit"
        onClick={handleClick}
        disabled={disabled}
      >
        <Box className="nid-icon-container-large">
          <AccountKeyIcon color={"white"} />
        </Box>
        {t("security.passkey.button.submit")}
      </Button>
      <BackButton
        to={path.security.root}
        buttonText={t("security.passkey.button.back")}
      />
    </Box>
  );
};

export const SecurityPasskeyFeature = () => {
  const { t } = useTranslation();
  const {
    status,
    submitting,
    revoking,
    clearRevokingStatus,
    handleRevokePasskeyConfirm,
    handleRegisterPasskey,
    handleRevokePassKey,
    passkeys,
    canCreatePasskeys,
    errorId,
    revokeModal: revoke,
    infoModal,
  } = useSecurityPasskeyFeature();

  if (!enableSecurityPasskey) {
    return null;
  }

  if (status !== "ok") {
    return <ContentSkeletonLoader />;
  }

  const passkeyCount = passkeys.length || 0;
  const modalType = revoking?.status === "success" ? "complete" : "confirm";

  return (
    <>
      {passkeyCount === 0 ? (
        <Box
          as="section"
          className="nid-section nid-section-overflow"
          data-testid={"security-passkey-empty"}
        >
          <Box className="nid-section-inner-gap0">
            <PasskeyBenefits>
              <PasskeyActionButtons
                onRegisterPassKey={handleRegisterPasskey}
                disabled={submitting}
                errorId={errorId}
              />
            </PasskeyBenefits>
          </Box>
        </Box>
      ) : (
        <Box className="nid-section-inner">
          <Box className="nid-column nid-item-gap24">
            {passkeys.map((item, index) => {
              const { id, aaguid, createdAt, lastAuthenticatedAt } = item;

              const aaguidInfo = aaguidInfoFn(aaguid);

              return (
                <PasskeyItem
                  key={id}
                  title={aaguidInfo?.name}
                  createdAtDateTime={convertToAtDateTime(createdAt)}
                  lastUsedAtDateTime={
                    lastAuthenticatedAt
                      ? convertToAtDateTime(lastAuthenticatedAt)
                      : undefined
                  }
                  handleClickRevokePassKey={() =>
                    handleRevokePasskeyConfirm(id)
                  }
                  testId={`passkey-item-${index}`}
                />
              );
            })}
            {!canCreatePasskeys && (
              <AlertMessage variant="info">
                <Typography size="14" color="blue120">
                  {t("security.passkey.limit")}
                </Typography>
              </AlertMessage>
            )}
            <PasskeyActionButtons
              onRegisterPassKey={handleRegisterPasskey}
              disabled={submitting || !canCreatePasskeys}
              errorId={errorId}
            />
          </Box>
        </Box>
      )}

      <Modal
        title={t(`security.passkey.delete.${modalType}_title`)}
        testId={`passkey-item-${revoking?.item.index}-delete-${modalType}-modal`}
        {...revoke.register}
      >
        {revoking && (
          <PasskeyRevokeModalContent
            title={aaguidInfoFn(revoking.item.aaguid)?.name}
            handleRevokePassKey={handleRevokePassKey}
            clearRevokingStatus={clearRevokingStatus}
            createdAtDateTime={convertToAtDateTime(revoking.item.createdAt)}
            lastUsedAtDateTime={
              revoking.item.lastAuthenticatedAt
                ? convertToAtDateTime(revoking.item.lastAuthenticatedAt)
                : undefined
            }
            revokingStatus={revoking.status}
            testId={`passkey-item-${revoking.item.index}`}
            closeModal={revoke.close}
          />
        )}
      </Modal>

      <Modal
        title={infoModal.id && t(`security.passkey.info.${infoModal.id}.title`)}
        testId="passkey-information-modal"
        {...infoModal.register}
      >
        <PasskeyInformationModalContent
          id={infoModal.id}
          closeModal={infoModal.close}
        />
      </Modal>
    </>
  );
};
