import { ArrowRightIcon, Box, Link, Typography } from "nikkei-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./NikkeiIdServices.module.css";

interface Option {
  id: string;
  service: string;
  url: string;
  logo: string;
}

interface DropdownProps {
  title: string;
  options: Option[];
  testId: string;
}

const Dropdown: React.FC<DropdownProps> = ({ title, options, testId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box className={styles.dropdownContainer}>
      <Box
        className={styles.dropdownHeader}
        onClick={toggleDropdown}
        data-testid={testId}
      >
        <Typography as="p" size="14" bold className="m-4">
          {title}
        </Typography>
        <Box className={styles.arrowContainer}>
          <ArrowRightIcon
            className={isOpen ? styles.arrowDown : styles.arrowUp}
          />
        </Box>
      </Box>
      <Box
        className={`${styles.dropdown} ${isOpen ? styles.dropdownOpen : ""}`}
      >
        <ul className={styles.dropdownList}>
          {options.map((option) => (
            <li key={option.id} className={styles.dropdownItem}>
              <Link href={option.url} data-testid={option.id} newTab>
                <Typography
                  as="span"
                  color="link"
                  className={styles.serviceName}
                >
                  {option.service}
                </Typography>
                <img
                  className={styles.logo}
                  src={`${import.meta.env.VITE_ORIGIN}${import.meta.env.VITE_URL_SERVICE_LOGOS}/${option.logo}`}
                  alt={option.service}
                />
              </Link>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export const NikkeiIdServices = () => {
  const { t } = useTranslation();

  const newsOptions: Option[] = [
    {
      id: "link-nikkei-denshiban",
      service: t("account_top.nikkei_id_services_options.nikkei_denshiban"),
      url: "https://www.nikkei.com/",
      logo: "nikkei-denshiban.svg",
    },
    {
      id: "link-nikkei-paper",
      service: t("account_top.nikkei_id_services_options.nikkei_paper"),
      url: "https://www.nikkei4946.com/",
      logo: "nikkei-paper.svg",
    },
    {
      id: "link-nikkei-digital-governance",
      service: "NIKKEI Digital Governance",
      url: "https://www.nikkei.com/prime/digital-governance",
      logo: "nikkei-digital-governance.svg",
    },
    {
      id: "link-nikkei-mobility",
      service: "NIKKEI Mobility",
      url: "https://www.nikkei.com/prime/mobility/",
      logo: "nikkei-mobility.svg",
    },
    {
      id: "link-nikkei-gx",
      service: "NIKKEI GX",
      url: "https://www.nikkei.com/prime/gx/",
      logo: "nikkei-gx.svg",
    },
    {
      id: "link-nikkei-tech-foresight",
      service: "NIKKEI Tech Foresight",
      url: "https://www.nikkei.com/prime/tech-foresight/",
      logo: "nikkei-tech-foresight.svg",
    },
    {
      id: "link-nikkei-ft-the-world",
      service: "NIKKEI FT the World",
      url: "https://www.nikkei.com/prime/ft/",
      logo: "nikkei-ft-the-world.svg",
    },
    {
      id: "link-minutes-by-nikkei",
      service: "Minutes by NIKKEI",
      url: "https://www.nikkei.com/prime/minutes/",
      logo: "minutes-by-nikkei.svg",
    },
    {
      id: "link-nikkei-financial",
      service: "NIKKEI Financial",
      url: "https://financial.nikkei.com/",
      logo: "nikkei-financial.svg",
    },
    {
      id: "link-nikkei-mj",
      service: t("account_top.nikkei_id_services_options.nikkei_mj"),
      url: "https://pr.nikkei.com/lp/mj_viewer/howto/",
      logo: "nikkei-mj.svg",
    },
    {
      id: "link-nikkei-business",
      service: t("account_top.nikkei_id_services_options.nikkei_business"),
      url: "https://business.nikkei.com/",
      logo: "nikkei-business.svg",
    },
    {
      id: "link-nikkei-xtech",
      service: t("account_top.nikkei_id_services_options.nikkei_xtech"),
      url: "https://xtech.nikkei.com/",
      logo: "nikkei-xtech.svg",
    },
    {
      id: "link-nikkei-xtrend",
      service: t("account_top.nikkei_id_services_options.nikkei_xtrend"),
      url: "https://xtrend.nikkei.com/",
      logo: "nikkei-xtrend.svg",
    },
    {
      id: "link-nikkei-xwoman",
      service: t("account_top.nikkei_id_services_options.nikkei_xwoman"),
      url: "https://woman.nikkei.com/",
      logo: "nikkei-xwoman.svg",
    },
    {
      id: "link-national-geographic",
      service: t("account_top.nikkei_id_services_options.national_geographic"),
      url: "https://natgeo.nikkeibp.co.jp/",
      logo: "national-geographic.svg",
    },
    {
      id: "link-nikkei-bizgate",
      service: t("account_top.nikkei_id_services_options.nikkei_bizgate"),
      url: "https://bizgate.nikkei.com/",
      logo: "nikkei-bizgate.svg",
    },
    {
      id: "link-nikkei-cnbc",
      service: t("account_top.nikkei_id_services_options.nikkei_cnbc"),
      url: "https://online.nikkei-cnbc.co.jp/",
      logo: "nikkei-cnbc.svg",
    },
    {
      id: "link-nikkeinext",
      service: "NIKKEI NEWS NEXT",
      url: "https://www.bs-tvtokyo.co.jp/nikkeinext/",
      logo: "nikkei-news-next.svg",
    },
  ];

  const businessOptions: Option[] = [
    {
      id: "link-nikkei-hr-watch",
      service: t("account_top.nikkei_id_services_options.nikkei_hr_watch"),
      url: "https://www.nikkei.com/jinji/",
      logo: "nikkei-hr-watch.svg",
    },
    {
      id: "link-nikkei-rc",
      service: t("account_top.nikkei_id_services_options.nikkei_rc"),
      url: "https://nkbb.nikkei.co.jp/rc/",
      logo: "nikkei-rc.svg",
    },
    {
      id: "link-nikkei-valuesearch",
      service: t("account_top.nikkei_id_services_options.nikkei_valuesearch"),
      url: "https://t21s.jp/vs_id_lounge",
      logo: "nikkei-valuesearch.svg",
    },
    {
      id: "link-nikkei-compass",
      service: "NIKKEI COMPASS",
      url: "https://www.nikkei.com/compass",
      logo: "nikkei-compass.svg",
    },
    {
      id: "link-nikkei-knowledge",
      service: "NIKKEI The KNOWLEDGE",
      url: "https://nkbb.nikkei.co.jp/km/",
      logo: "nikkei-knowledge.svg",
    },
  ];

  const servicesOptions: Option[] = [
    {
      id: "link-nikkei-art",
      service: "art NIKKEI",
      url: "https://art.nikkei.com/",
      logo: "nikkei-art.svg",
    },
    {
      id: "link-nikkei-career",
      service: t("account_top.nikkei_id_services_options.nikkei_career"),
      url: "https://career.nikkei.com/",
      logo: "nikkei-career.svg",
    },
    {
      id: "link-nikkei-gooday",
      service: t("account_top.nikkei_id_services_options.nikkei_gooday"),
      url: "https://gooday.nikkei.co.jp/",
      logo: "nikkei-gooday.svg",
    },
    {
      id: "link-nikkei-school",
      service: t("account_top.nikkei_id_services_options.nikkei_school"),
      url: "https://school.nikkei.co.jp/",
      logo: "nikkei-school.svg",
    },
    {
      id: "link-nikkei-reskill",
      service: t("account_top.nikkei_id_services_options.nikkei_reskill"),
      url: "https://reskill.nikkei.com/",
      logo: "nikkei-reskill.svg",
    },
    {
      id: "link-nikkei-pclub",
      service: t("account_top.nikkei_id_services_options.nikkei_pclub"),
      url: "https://ps.nikkei.com/pclub/",
      logo: "nikkei-pclub.svg",
    },
    {
      id: "link-nikkei-magazine",
      service: "The NIKKEI MAGAZINE",
      url: "https://magazine.nikkei.com/",
      logo: "nikkei-magazine.svg",
    },
    {
      id: "link-nikkei-nwp",
      service: t("account_top.nikkei_id_services_options.nikkei_nwp"),
      url: "https://nwp.nikkei.com/",
      logo: "nikkei-nwp.svg",
    },
    {
      id: "link-nikkei-shopping",
      service: t("account_top.nikkei_id_services_options.nikkei_shopping"),
      url: "https://shopping.nikkei.co.jp/",
      logo: "nikkei-shopping.svg",
    },
    {
      id: "link-nikkei-officepass",
      service: "NIKKEI OFFICE PASS",
      url: "https://officepass.nikkei.com/",
      logo: "nikkei-officepass.svg",
    },
    {
      id: "link-nikkei-adnet",
      service: t("account_top.nikkei_id_services_options.nikkei_adnet"),
      url: "https://adnet.nikkei.co.jp/",
      logo: "nikkei-adnet.png",
    },
    {
      id: "link-nikkei-lissn",
      service: t("account_top.nikkei_id_services_options.nikkei_lissn"),
      url: "https://lissn.nikkei.com/",
      logo: "nikkei-lissn.svg",
    },
  ];

  return (
    <>
      <Box className={styles.header}>
        <Typography bold>
          {t("account_top.title.nikkei_id_services")}
        </Typography>
      </Box>
      <Box>
        <Dropdown
          title={t("account_top.title.news_options")}
          options={newsOptions}
          testId={"dropdown-news-options"}
        />
        <Dropdown
          title={t("account_top.title.business_options")}
          options={businessOptions}
          testId={"dropdown-business-options"}
        />
        <Dropdown
          title={t("account_top.title.services_options")}
          options={servicesOptions}
          testId={"dropdown-services-options"}
        />
      </Box>
    </>
  );
};
