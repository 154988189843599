import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";

import {
  type SecurityMfaEmailValues,
  useEmailMfaSetupToken,
} from "@/features/Security/Mfa/Email/SecurityMfaEmailFormProvider";
import { path } from "@/routes";
import { useMfaStatus, useUserStatus } from "nid-common";
import { postMfaSetupEmailStart } from "nid-common/api/account";

export const useSecurityMfaEmailInputFeature = () => {
  const { loginId } = useUserStatus();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setMfaSetupToken, setEmailConfirm } = useEmailMfaSetupToken();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const useMfaStatusReturn = useMfaStatus();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t("security.mfa.email.index.errors.required"))
      .email(t("security.mfa.email.index.errors.invalid_email")),
  });

  const { register, handleSubmit, formState, watch, setValue } =
    useFormContext<SecurityMfaEmailValues>();

  const onSubmit = async (data: SecurityMfaEmailValues) => {
    const inputEmailSameAsLoginId = data.emailSameAsLoginId === "true";
    const submitEmail = inputEmailSameAsLoginId ? undefined : data.email;

    try {
      setIsSubmitting(true);
      const response = await postMfaSetupEmailStart({
        email: submitEmail,
      });
      const email = (inputEmailSameAsLoginId ? loginId : data.email) || "";
      setValue("email", email);
      setEmailConfirm(email);
      setMfaSetupToken(response.data.mfa_setup_token);
      navigate(path.security.mfa.email.confirm);
    } catch (_) {
      navigate(path.error.root);
    } finally {
      setIsSubmitting(false);
    }
  };

  const emailSameAsLoginId = watch(
    "emailSameAsLoginId",
    loginId === useMfaStatusReturn.data?.email ? "true" : "false",
  );

  const registers = {
    emailSameAsLoginId: register("emailSameAsLoginId", {
      onChange: (e) => {
        if (e.target.value === "true") {
          setValue("email", "");
        }
      },
    }),
    email: register("email", {
      validate:
        emailSameAsLoginId === "true"
          ? undefined
          : async (value?: string) => {
              if (value === undefined) {
                return t("security.mfa.email.index.errors.required");
              }
              try {
                await schema.validate({ email: value });
              } catch (e) {
                return (e as yup.ValidationError).message;
              }
              return undefined;
            },
    }),
  };

  useEffect(() => {
    const email = useMfaStatusReturn.data?.email;
    const isSameAsLoginId =
      email === loginId || email === undefined ? "true" : "false";

    setValue("emailSameAsLoginId", isSameAsLoginId);
    setValue("email", isSameAsLoginId === "true" ? "" : (email ?? ""));
  }, [useMfaStatusReturn.data?.email]);

  return {
    email: loginId,
    registers,
    handleSubmit: handleSubmit(onSubmit),
    emailFieldEnabled: emailSameAsLoginId === "true",
    buttonEnabled: formState.isSubmitting || isSubmitting,
    errorMessage: formState.errors.email?.message,
  };
};
