import type React from "react";

import { Box, Link, NikkeiIDLogo, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { path } from "@/routes";
import { Row } from "../Row";
import { HeaderAccountMenu } from "./HeaderAccountMenu";
import { HeaderHorizontalNav } from "./HeaderHorizontalNav";

const DISPLAY_NAME = "Nid.Header";

type HeaderProps = {
  leadText?: string;
  logoutText?: string;
  handleLogout?: () => void;
};

const Header: React.FC<HeaderProps> = ({
  logoutText = "ログアウト",
  handleLogout,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const NavList = [
    {
      title: t("layout.navigation.title.top"),
      to: path.root,
      id: "top",
    },
    {
      title: t("layout.navigation.title.personal"),
      to: path.personal.root,
      id: "personal",
    },
    {
      title: t("layout.navigation.title.payments"),
      to: path.payments.root,
      id: "payments",
    },
    {
      title: t("layout.navigation.title.security"),
      to: path.security.root,
      id: "security",
    },
    {
      title: t("layout.navigation.title.services"),
      to: path.services.root,
      id: "services",
    },
    {
      title: t("layout.navigation.title.newsletters"),
      to: path.newsletters.root,
      id: "newsletters",
    },
  ];

  const getCurrentPath = (locationPath: string) => {
    if (locationPath === "/") {
      return "top";
    }
    const pathArray = locationPath.split("/");
    const foundItem = NavList.find((item) => pathArray.includes(item.id));
    if (foundItem) {
      return foundItem.id;
    }
    return "top";
  };

  return (
    <Box as="header" className="nid-header w-full" data-testid="header">
      <Box as="div" className="nid-header--fixed">
        <Box as="nav" className="nid-navbar">
          <Row>
            <Link
              data-testid="logo-link"
              linkClassName="nid-header-logo"
              to="/"
            >
              <NikkeiIDLogo width={122} />
            </Link>
            <Box className="nid-hidden-mobile">
              <Typography as="span" size="12" className="nid-header-lead">
                {t("description")}
              </Typography>
            </Box>
          </Row>
          <HeaderAccountMenu
            handleLogout={handleLogout}
            logoutText={logoutText}
          />
        </Box>
        <HeaderHorizontalNav
          navList={NavList}
          currentPath={getCurrentPath(location.pathname)}
        />
      </Box>
    </Box>
  );
};

Header.displayName = DISPLAY_NAME;
const Root = Header;
export { Root, Header };
export type { HeaderProps };
export default Header;
