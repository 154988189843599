import { AlertMessage, Box, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import { ServiceContractItem } from "@/components/ui/Services/ServiceContractItem";
import styles from "@/features/Services/Contract/Contract.module.css";
import type React from "react";

type ContractContentProps = {
  contract: {
    service: string;
    plan: string;
    expiration?: {
      year: number;
      month: number;
      date: number;
    };
    isFreeTrial?: boolean;
    isPaidPlan: boolean;

    note?: React.ReactNode;
  };
};

export const ContractContent: React.FC<ContractContentProps> = ({
  contract,
}) => {
  const { t } = useTranslation();
  return (
    <Box as="section" className="nid-section nid-section-overflow mb-0">
      <Box className="nid-section-inner">
        {contract.isFreeTrial && (
          <AlertMessage
            variant="info"
            className={styles.notification}
            data-testid={"free-trial-notification"}
          >
            <Typography as="p" size="14" color="blue120">
              {t("services.service_contract_cancel.note.free_trial_cancel")}
            </Typography>
          </AlertMessage>
        )}
        <Box className="nid-section-inner-header">
          <Typography
            as="h2"
            size="18"
            bold
            color="blue120"
            data-testid={"contract-content-title"}
          >
            <Box className="nid-section-title">
              {t("services.service_contract.content.title")}
            </Box>
          </Typography>
        </Box>
        <Box className="nid-section-body">
          <ServiceContractItem
            title={t("services.service_contract.content.label.service")}
            text={contract.service}
            testId="service-name"
          />

          <ServiceContractItem
            title={t("services.service_contract.content.label.plan")}
            text={contract.plan}
            testId="plan"
            hasTopLine
          />

          {contract.isPaidPlan && contract.expiration && (
            <ServiceContractItem
              title={t("services.service_contract.content.label.expiration")}
              text={t(
                "services.service_contract.content.date_format",
                contract.expiration,
              )}
              testId="plan-terminate-date"
              hasTopLine
            />
          )}

          {contract.note && (
            <ServiceContractItem
              text={contract.note}
              testId="note"
              hasTopLine
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
