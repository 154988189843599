import { Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { PersonalEmailChangeFormProvider } from "@/features/Personal/EmailChange/PersonalEmailChangeFormProvider";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";

import { Layout } from "../../../../components/ui/Layout";

export const PersonalEmailChangePageWrapper = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("breadcrumbs.personal_email")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.personal.root} size="14">
            {t("breadcrumbs.personal")}
          </Link>
          <Typography as="span" size="14">
            {t("breadcrumbs.personal_email")}
          </Typography>
        </>
      }
      description={t("personal.description")}
    >
      <LoginWallProvider maxAge={600}>
        <PersonalEmailChangeFormProvider>
          <Outlet />
        </PersonalEmailChangeFormProvider>
      </LoginWallProvider>
    </Layout>
  );
};

export default PersonalEmailChangePageWrapper;
