import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { Layout } from "@/components/ui/Layout";
import { PaymentsUnpaidAlertFeature } from "@/components/ui/Payments/PaymentsAlertFeature";
import { usePaymentUpdateCompletePageAssets } from "@/features/Payments/Update/usePaymentUpdateCompletePageAssets";
import { path } from "@/routes";
import { ContentSkeletonLoader, LoginWallProvider } from "nid-common";
import { Box, Link, LinkButton, Typography } from "nikkei-ui";
import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./complete.module.css";

const PaymentsUpdateCompletePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { value } = usePaymentUpdateCompletePageAssets();

  useEffect(() => {
    if (value === undefined || value.services.length === 0) {
      navigate(path.payments.root);
    }
  }, [value]);

  return (
    <Layout
      title={t("breadcrumbs.payments_card_update_complete")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.payments.root} size="14">
            {t("breadcrumbs.payments")}
          </Link>
          <Typography as="span" size="14">
            {t("breadcrumbs.payments_card_update_complete")}
          </Typography>
        </>
      }
      description={t("payments.top.description")}
    >
      <LoginWallProvider>
        <PaymentsUnpaidAlertFeature />
        <ArticleTitle
          testId="article-payments-update-complete"
          title={t("payments.update.complete.title")}
        >
          <Typography as="p" className="nid-article-description">
            {t("payments.update.complete.description")}
          </Typography>
        </ArticleTitle>
        {value !== undefined ? (
          <Box className="nid-section-inner">
            <Box className="nid-section-body" data-testid="applied-services">
              <ul>
                {value.services.map((service) => (
                  <Typography
                    as="li"
                    className={styles.serviceItem}
                    key={service.serviceId}
                  >
                    {service.name}
                    {service.planName && <>（{service.planName}）</>}
                  </Typography>
                ))}
              </ul>
            </Box>

            {value.backButtonLink ? (
              <LinkButton
                to={value.backButtonLink}
                data-testid="submit"
                size="full"
                className="mt-10"
              >
                {t("payments.update.complete.button.back_to_service")}
              </LinkButton>
            ) : (
              <LinkButton
                to={path.payments.root}
                data-testid="submit"
                size="full"
                className="mt-10"
              >
                {t("payments.update.complete.button.back")}
              </LinkButton>
            )}
          </Box>
        ) : (
          <ContentSkeletonLoader />
        )}
      </LoginWallProvider>
    </Layout>
  );
};

export default PaymentsUpdateCompletePage;
