import { AlertMessage, Box, Link, Typography } from "nikkei-ui";

import { Trans, useTranslation } from "react-i18next";

import { ServiceUnavailableHoursModal } from "@/components/ui/Modal/ServiceUnavailableHoursModal";
import UndoCancelContract from "@/components/ui/Modal/UndoCancelContract";
import {
  ServiceContractItem,
  ServiceContractItemWithAmount,
} from "@/components/ui/Services/ServiceContractItem";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { useServiceContractFeature } from "@/features/Services/Contract/hooks/useServiceContractFeature";
import type { ContractDetail } from "@/hooks/useContractDetail";
import { path } from "@/routes";
import { PageSkeletonLoader } from "nid-common";
import type React from "react";
import { CommonNavList, ServiceHeader } from "../ServiceCommon";
import styles from "./Contract.module.css";
import { ContractCancelLink } from "./ContractCancelLink";
import { ContractNotice } from "./ContractNotice";

type ContractContentProps = ContractDetail & {
  undoCancelContractCompleted: boolean;
};

const getNextMonth = (month: number): number => {
  return (month % 12) + 1;
};

const ContractContent: React.FC<ContractContentProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Box as="section" className="nid-section nid-section-overflow mb-0">
      <Box className="nid-section-inner">
        {props.undoCancelContractCompleted && (
          <AlertMessage variant="info" className={styles.notification}>
            <Typography
              as="p"
              size="14"
              color="blue120"
              data-testid={"cancel-completed-notification"}
            >
              {t(
                "services.service_contract.undo_cancel_complete.modal.description",
              )}
            </Typography>
          </AlertMessage>
        )}
        {props.firstFreeEndDate &&
          props.afterFreePayment &&
          !props.terminationInfo && (
            <AlertMessage
              variant="info"
              className={styles.notification}
              testId={"first-free-notification"}
            >
              <Typography as="p" size="14" color="blue120">
                {t("services.service_contract.after_free_payment_notice", {
                  free_end_year: props.firstFreeEndDate.year,
                  free_end_month: props.firstFreeEndDate.month,
                  free_end_date: props.firstFreeEndDate.date,
                  after_free_payment_year:
                    props.afterFreePayment.paymentDate.year,
                  after_free_payment_month:
                    props.afterFreePayment.paymentDate.month,
                  after_free_payment_date:
                    props.afterFreePayment.paymentDate.date,
                  regular_payment_start_month: getNextMonth(
                    props.afterFreePayment.paymentDate.month,
                  ),
                })}
              </Typography>
            </AlertMessage>
          )}
        {props.terminationInfo && (
          <AlertMessage
            variant="danger"
            className={styles.notification}
            testId={"cancel-notification"}
          >
            <Typography as="p" size="14" color="red90">
              {t("services.service_contract.cancel_notice", {
                cancel_year: props.terminationInfo.applyDate.year,
                cancel_month: props.terminationInfo.applyDate.month,
                cancel_date: props.terminationInfo.applyDate.date,
              })}
              {props.firstFreeEndDate &&
                t("services.service_contract.cancel_notice_first_free")}
            </Typography>
          </AlertMessage>
        )}
        <Box className="nid-section-inner-header">
          <Typography
            as="h2"
            size="18"
            bold
            color="blue120"
            testId="current-contract"
          >
            <Box className="nid-section-title">
              {props.terminationInfo
                ? t("services.service_contract.content.title_canceled")
                : t("services.service_contract.content.title")}
            </Box>
          </Typography>
        </Box>
        <Box className="nid-section-body">
          <ServiceContractItem
            title={t("services.service_contract.content.label.service")}
            text={props.serviceName}
            testId="service-name"
          />
          <ServiceContractItem
            title={t("services.service_contract.content.label.plan")}
            text={props.planName}
            testId="plan-name"
            hasTopLine
          />
          {props.planPriceInfo && (
            <ServiceContractItemWithAmount
              title={t("services.service_contract.content.price_label")}
              text={t("services.service_contract.content.price_type.default")}
              amount={props.planPriceInfo.price}
              testId="plan-price"
              discountAmount={
                props.planPriceInfo.setDiscountFlag === "applied"
                  ? props.planPriceInfo.setDiscountedPrice
                  : undefined
              }
              hasTopLine
            />
          )}
          {props.afterFreePayment && (
            <ServiceContractItemWithAmount
              title={t(
                "services.service_contract.content.after_free_payment.label",
              )}
              text={t("services.service_contract.content.price_type.default")}
              amount={props.afterFreePayment.price}
              testId="after-free-payment-price"
              discountAmount={
                props.afterFreePayment.setDiscountFlag === "applied"
                  ? props.afterFreePayment.setDiscountedPrice
                  : undefined
              }
              hasTopLine
            >
              <Typography as="p" size="14">
                {t(
                  "services.service_contract.content.after_free_payment.detail",
                  {
                    month: props.afterFreePayment.paymentDate.month,
                    days: props.afterFreePayment.days,
                  },
                )}
              </Typography>
            </ServiceContractItemWithAmount>
          )}
          {props.planPriceInfo?.setDiscountFlag === "not_applied" &&
            props.planPriceInfo.setDiscountedPrice !== undefined && (
              <ContractNotice
                title={t("services.service_contract.set_discount_notice.title")}
                testId="set-discount-not-applied"
              >
                <Typography as="p" size="14">
                  <Trans
                    i18nKey="services.service_contract.set_discount_notice.not_applied"
                    values={{
                      discounted_price:
                        props.planPriceInfo.setDiscountedPrice.toLocaleString(
                          "ja-JP",
                        ),
                    }}
                    components={{
                      discounted_price: (
                        <Typography as="span" size="14" color="red90" />
                      ),
                      about_set_discount: (
                        <Link
                          href={import.meta.env.VITE_URL_NIKKEI_HELP_PAYMENT}
                          newTab
                          size="14"
                        />
                      ),
                    }}
                  />
                </Typography>
              </ContractNotice>
            )}
          {props.planPriceInfo?.setDiscountFlag === "applied" && (
            <ContractNotice testId="set-discount-applied">
              <Typography as="div" size="14">
                <Trans
                  i18nKey="services.service_contract.set_discount_notice.applied"
                  components={{
                    about_set_discount: (
                      <Link
                        href={import.meta.env.VITE_URL_NIKKEI_HELP_PAYMENT}
                        newTab
                        size="14"
                      />
                    ),
                  }}
                />
              </Typography>
            </ContractNotice>
          )}
          {props.terminationInfo && (
            <>
              <ServiceContractItem
                title={t("services.service_contract.content.label.expiration")}
                text={t("services.service_contract.content.date_format", {
                  year: props.terminationInfo.applyDate.year,
                  month: props.terminationInfo.applyDate.month,
                  date: props.terminationInfo.applyDate.date,
                })}
                testId="contract-expiration-date"
                hasTopLine={true}
              />
              <ContractNotice testId="contract-cancel-info">
                <Typography as="div" size="14">
                  {t("services.service_contract.cancel_info", {
                    end_year: props.terminationInfo.applyDate.year,
                    end_month: props.terminationInfo.applyDate.month,
                    end_date: props.terminationInfo.applyDate.date,
                  })}
                </Typography>
              </ContractNotice>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const CouponContent: React.FC<
  Exclude<ContractDetail["appliedCouponInfo"], undefined>
> = (props) => {
  const { t } = useTranslation();
  return (
    <Box as="section" className="nid-section nid-section-overflow mb-0">
      <Box className="nid-section-inner">
        <Box className="nid-section-inner-header">
          <Typography as="h2" size="18" bold color="blue120">
            <Box className="nid-section-title">
              {t("services.service_contract.coupon.title")}
            </Box>
          </Typography>
        </Box>
        <Box className="nid-section-body">
          <ServiceContractItem
            title={t("services.service_contract.coupon.coupon_label")}
            text={props.name}
            testId="coupon-name"
          />
          <ServiceContractItemWithAmount
            title={t("services.service_contract.coupon.discount_value_label")}
            text={t("services.service_contract.coupon.discount_end_date", {
              year: props.discountEndDate.year,
              month: props.discountEndDate.month,
            })}
            amount={props.discountPrice}
            hasTopLine={true}
            testId="coupon-discount-value"
          />
        </Box>
      </Box>
    </Box>
  );
};

export const ServiceContractFeature = (): JSX.Element => {
  const { t } = useTranslation();
  const r = useServiceContractFeature();

  if (r.status !== "ok") return <PageSkeletonLoader />;

  return (
    <>
      <ServiceHeader
        name={r.name}
        logo={r.logo}
        description={t("services.service_contract.description")}
      />
      <Box className="nid-section-transparent nid-section-inner mb-0 pt-6 pb-6">
        <ServiceUnavailableHoursModal />
      </Box>
      <Box className="nid-column-item-initial nid-item-gap24">
        <ContractContent
          {...r.contractDetail}
          undoCancelContractCompleted={r.undoCancelContractCompleted}
        />
        {r.contractDetail?.appliedCouponInfo && (
          <CouponContent {...r.contractDetail.appliedCouponInfo} />
        )}
        {!r.contractDetail.terminationInfo && (
          <Box className="nid-item-center">
            <ContractCancelLink name={r.name} testId="link-contract-cancel" />
          </Box>
        )}
        {r.contractDetail.terminationInfo && (
          <UndoCancelContract
            onStart={r.onUndoCancelStart}
            onFinished={r.onUndoCancelFinished}
          />
        )}
        <CommonNavList />
      </Box>
      <Box className="nid-item-center mt-4">
        <BackButton
          to={path.services.service(r.clientId)}
          buttonText={t("services.service_contract.back")}
        />
      </Box>
    </>
  );
};

export default ServiceContractFeature;
